import React, { useState } from "react";
import {
  Button,
  Card,
  RadioGroup,
  RadioGroupItem,
  Textarea,
  Separator,
} from "@mindstonehq/ui";
import metrics from "~/utils/metrics";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { classNames } from "~/utils";

interface NPSSurveyProps {
  onSubmitScore: (score: number) => void;
  onSubmitFeedback: (feedback: string) => void;
  onDismiss: () => void;
}

export default function NPSSurvey({
  onSubmitScore,
  onSubmitFeedback,
  onDismiss,
}: NPSSurveyProps) {
  const [selectedScore, setSelectedScore] = useState<number | null>(null);
  const [feedback, setFeedback] = useState("");
  const [submitted, setSubmitted] = useState(false);
  
  // Create array of possible scores from 0 to 10
  const scores = Array.from({ length: 11 }, (_, i) => i);
  
  // Determine if score is in high range (7-10) or low range (0-6)
  const isHighScore = selectedScore !== null && selectedScore >= 7;
  const isLowScore = selectedScore !== null && selectedScore <= 6;
  
  // Handle score selection
  const handleScoreSelect = (value: string) => {
    const numScore = parseInt(value, 10);
    if (!isNaN(numScore)) {
      setSelectedScore(numScore);
      onSubmitScore(numScore);
    }
  };
  
  // Handle feedback submission
  const handleSubmitFeedback = () => {
    onSubmitFeedback(feedback.trim());
    setSubmitted(true);
  };
  
  // Handle survey dismissal
  const handleDismiss = () => {
    onDismiss();
  };
  
  return (
    <div className="fixed inset-x-0 -bottom-2 z-50 flex justify-center">
      <div className="w-full max-w-4xl mx-8">
        <div className="bg-[#dbe3ff] p-6 rounded-xl shadow-lg relative">
          <button
            onClick={handleDismiss}
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
            aria-label="Dismiss survey"
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
          
          <>
              {selectedScore === null ? (
                <div className="text-center pb-8">
                  <h2 className="text-lg sm:text-2xl font-semibold text-gray-900 mb-2">
                    How likely are you to recommend Mindstone to a friend or colleague?
                  </h2>
                  <p className="text-sm sm:text-base text-gray-600 mb-6 sm:mb-8">
                    0 - Not likely at all to 10 - Extremely likely
                  </p>
                  
                  <RadioGroup
                    value={selectedScore !== null ? String(selectedScore) : undefined}
                    onValueChange={handleScoreSelect}
                    className="flex justify-center"
                  >
                    <div className="inline-grid grid-cols-5 md:grid-cols-11 gap-2 mx-auto">
                      {scores.map((score) => (
                        <div key={score} className={`flex justify-center ${score === 10 ? "col-span-5 md:col-span-1" : ""}`}>
                          <RadioGroupItem
                            value={String(score)}
                            id={`score-${score}`}
                            className="peer sr-only"
                          />
                          <label
                            htmlFor={`score-${score}`}
                            className="flex h-10 w-10 sm:h-12 sm:w-12 cursor-pointer items-center justify-center rounded-md border bg-white text-base text-slate-500 sm:text-lg font-normal shadow-sm peer-data-[state=checked]:border-2 peer-data-[state=checked]:border-[#6e56cf] hover:bg-gray-50"
                          >
                            {score}
                          </label>
                        </div>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              ) : (
                <>
                  <div className="text-center mb-4">
                    <h2 className="text-lg sm:text-2xl font-semibold text-gray-900 mb-2">
                      {isHighScore
                        ? "Thanks for your feedback! Is there anything we could do to improve Mindstone for you?"
                        : "We appreciate your honesty. What's one thing we could improve to make Mindstone more valuable for you?"}
                    </h2>
                  </div>
                  <Textarea
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Your feedback helps us improve..."
                    className="min-h-24 mb-4 w-full"
                  />
                  <div className="flex justify-center">
                    <Button onClick={handleSubmitFeedback}>
                      Submit Feedback
                    </Button>
                  </div>
                </>
              )}
            </>
        </div>
      </div>
    </div>
  );
} 