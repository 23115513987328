import React, { useEffect, useState } from "react";
import { useUser, useCurrentSpace } from "~/hooks/context-hook";
import { useMutation } from "@apollo/client";
import { SubmitNpsSurveyResponseDocument } from "~/graphql/__generated";
import NPSSurvey from "./nps-survey";
import metrics from "~/utils/metrics";
import { isFrontend } from "~/utils/is-frontend";
import { captureException } from "@sentry/react";

// Constants for localStorage keys and timing
const NPS_SURVEY_DATA_KEY = "nps_survey_data";

interface NPSSurveyData {
  dismissedAt?: string;
  completedAt?: string;
}

// Number of days after sign-up to show survey
const DAYS_UNTIL_SURVEY = 14;
// Number of days after dismissal to show survey again
const DAYS_UNTIL_RESHOW = 28;

export default function NPSSurveyContainer() {
  const [shouldShow, setShouldShow] = useState(false);
  const [selectedScore, setSelectedScore] = useState<number | null>(null);
  const userData = useUser();
  const currentSpace = useCurrentSpace();
  const [submitNPSSurvey] = useMutation(SubmitNpsSurveyResponseDocument);
  const frontend = isFrontend();

  // Helper function to get NPS survey data from localStorage
  const getNPSSurveyData = (): NPSSurveyData => {
    if (!frontend) return {};

    try {
      const dataStr = localStorage.getItem(NPS_SURVEY_DATA_KEY);
      return dataStr ? JSON.parse(dataStr) : {};
    } catch (err) {
      console.error("Error parsing NPS survey data:", err);
      return {};
    }
  };

  // Helper function to save NPS survey data to localStorage
  const saveNPSSurveyData = (data: Partial<NPSSurveyData>) => {
    if (!frontend) return;

    try {
      const currentData = getNPSSurveyData();
      const updatedData = { ...currentData, ...data };
      localStorage.setItem(NPS_SURVEY_DATA_KEY, JSON.stringify(updatedData));
    } catch (err) {
      console.error("Error saving NPS survey data:", err);
    }
  };

  // Determine if we should show the survey based on user data and localStorage
  useEffect(() => {
    if (!frontend) {
      return;
    }

    // Make sure userData exists
    if (!userData) {
      return;
    }

    // Check if user is in an assessment or learning screen
    const currentPath = window.location.pathname;
    const isLearningOrAssessment =
      currentPath.includes("/assessment") || currentPath.includes("/learning"); //TODO: don't show on daily cards

    if (isLearningOrAssessment) {
      return; // Don't show on learning or assessment screens
    }

    // Get survey data from localStorage
    const surveyData = getNPSSurveyData();

    // Check if the survey was already completed
    if (surveyData.completedAt) {
      return; // Don't show if already completed
    }

    // Check if the survey was dismissed recently
    if (surveyData.dismissedAt) {
      // Handle null case by ensuring dismissedAt is a string
      const dismissedDate = new Date(surveyData.dismissedAt);
      const daysSinceDismissal = Math.floor(
        (Date.now() - dismissedDate.getTime()) / (1000 * 60 * 60 * 24),
      );

      if (daysSinceDismissal < DAYS_UNTIL_RESHOW) {
        return; // Don't show if dismissed less than the reshow period
      }
    }

    // Skip survey if user is not enrolled in any program
    // Check if the user has any enrolled programs by examining the current space
    const enrolledTotal = currentSpace?.programs?.enrolled?.total || 0;
    const isEnrolledInProgram = enrolledTotal > 0;

    if (!isEnrolledInProgram) {
      return; // Don't show if user is not enrolled in any program
    }

    // Check if the user's account is old enough
    if (userData?.joinedDate) {
      try {
        // The joinedDate is always in format 'YYYY-MM-DD'
        const signupDate = new Date(userData.joinedDate);

        // Additional validation to ensure date is valid
        if (signupDate.toString() === "Invalid Date") {
          return;
        }

        const daysSinceSignup = Math.floor(
          (Date.now() - signupDate.getTime()) / (1000 * 60 * 60 * 24),
        );

        if (daysSinceSignup >= DAYS_UNTIL_SURVEY) {
          setShouldShow(true);
          metrics.track("nps_survey_shown_fe");
        }
      } catch (err) {
        // Log any errors during date processing
        captureException(err, {
          tags: {
            component: "NPSSurveyContainer",
            action: "processJoinedDate",
          },
          extra: {
            userId: userData?.id,
            joinedDate: userData.joinedDate,
          },
        });
      }
    }
  }, [userData, currentSpace, frontend]);

  // Handle score submission - stores the score for later submission
  const handleSubmitScore = (score: number) => {
    if (!userData) return; // Safety check

    setSelectedScore(score);
    metrics.track("nps_score_selected_fe", { score });
  };

  // Shared function to submit NPS response that handles both feedback and dismissal cases
  const submitNPSResponse = async (
    feedback: string,
    actionType: "feedback" | "dismiss",
  ) => {
    if (!userData || selectedScore === null) return false; // Safety check

    try {
      await submitNPSSurvey({
        variables: {
          score: selectedScore,
          feedback,
          mindspaceId: currentSpace?.id,
        },
      });

      if (actionType === "feedback") {
        metrics.track("nps_feedback_submitted_fe", {
          score: selectedScore,
          feedback_length: feedback.length,
        });
      } else {
        metrics.track("nps_dismissed_after_score_fe", { score: selectedScore });
      }

      return true;
    } catch (error) {
      console.error(`Failed to submit NPS ${actionType}:`, error);
      // Log error to Sentry
      captureException(error, {
        tags: {
          component: "NPSSurveyContainer",
          action:
            actionType === "feedback" ? "submitFeedback" : "dismissWithScore",
        },
        extra: {
          userId: userData?.id,
          score: selectedScore,
          spaceId: currentSpace?.id,
          ...(actionType === "feedback" && {
            feedbackLength: feedback?.length || 0,
          }),
        },
      });

      return false;
    }
  };

  // Handle feedback submission - submits both score and feedback in a single mutation
  const handleSubmitFeedback = async (feedback: string) => {
    // Immediately hide the survey for better UX
    if (frontend) {
      saveNPSSurveyData({ completedAt: new Date().toISOString() });
      setShouldShow(false);
    }

    // Submit in the background
    submitNPSResponse(feedback, "feedback").catch((error) => {
      console.error("Background NPS feedback submission failed:", error);
      // Still capture the exception in Sentry
      captureException(error, {
        tags: {
          component: "NPSSurveyContainer",
          action: "backgroundFeedbackSubmission",
        },
        extra: {
          userId: userData?.id,
          score: selectedScore,
          spaceId: currentSpace?.id,
          feedbackLength: feedback?.length || 0,
        },
      });
    });
  };

  // Handle dismissal - ensures score is submitted even if feedback is dismissed
  const handleDismiss = async () => {
    if (frontend) {
      // Store dismissal time in localStorage
      saveNPSSurveyData({ dismissedAt: new Date().toISOString() });

      // Immediately hide the survey for better UX
      setShouldShow(false);

      // If a score was selected, submit it in the background even though feedback was dismissed
      if (selectedScore !== null) {
        // Don't await - let it happen in the background
        submitNPSResponse("", "dismiss").catch((error) => {
          console.error("Background NPS submission failed:", error);
          // Still capture the exception in Sentry
          captureException(error, {
            tags: {
              component: "NPSSurveyContainer",
              action: "backgroundDismiss",
            },
            extra: {
              userId: userData?.id,
              score: selectedScore,
              spaceId: currentSpace?.id,
            },
          });
        });
      } else {
        metrics.track("nps_dismissed_without_score_fe");
      }
    }
  };

  // Only render the survey component if we should show it and userData exists
  if (!shouldShow) {
    return null;
  }

  return (
    <NPSSurvey
      onSubmitScore={handleSubmitScore}
      onSubmitFeedback={handleSubmitFeedback}
      onDismiss={handleDismiss}
    />
  );
}
