import PageLayout from "~/components/containers/app-layout";
import React from "react";
import { Outlet, ShouldRevalidateFunction, useLoaderData } from "react-router";
import { LoaderFunctionArgs, redirect } from "react-router";
import { getAccessToken } from "~/utils/ensure-authenticated.server";
import { getServerClient } from "~/graphql";
import {
  SpacesDocument,
  SpacesQuery,
  SpacesQueryVariables,
} from "~/graphql/__generated";
import { useCurrentSpace } from "~/hooks/context-hook";
import { currentSpaceUniqueName } from "~/hooks/check-authenticated";

export const shouldRevalidate: ShouldRevalidateFunction = ({
  currentUrl,
  nextUrl,
  defaultShouldRevalidate,
}) => {
  if (currentUrl.pathname === nextUrl.pathname) {
    // If it's exactly the same path name, it means we are changing the search params. No need to revlidate, right?
    return false;
  }
  return defaultShouldRevalidate;
};

export async function loader({ request }: LoaderFunctionArgs) {
  // ensureAuthenticated(request);
  const accessToken = await getAccessToken(request);
  let url = new URL(request.url);
  if (accessToken) {
    const { data: spacesData, error } = await getServerClient()
      .query<SpacesQuery, SpacesQueryVariables>({
        query: SpacesDocument,
        fetchPolicy: "no-cache",
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      })
      .catch((error) => {
        throw error;
      });
    if (error) {
      throw error;
    }
    if (!spacesData?.mindspaces.all.length) {
      // TODO: Redirect to wanna join Mindstone AI or not.
      const uniqueName = currentSpaceUniqueName(request);
      const url = new URL(request.url);
      const host = url.hostname.replace(uniqueName, "app");
      // throw "Broken";
      return redirect(`https://${host}/migrate`, 302);
    }

    return { spaces: spacesData?.mindspaces.all, url: url.href };
  }
  return {
    spaces: [],
    url: url.href,
  };
}

export default function Route() {
  const data = useLoaderData<typeof loader>();
  const currentSpace = useCurrentSpace();
  return (
    <PageLayout spaces={data.spaces} currentSpace={currentSpace} url={data.url}>
      <Outlet />
    </PageLayout>
  );
}
